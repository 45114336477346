.product-id-tag {
  background-color: var(--product-info-tag-background-color);
  color: var(--product-info-main-color);

  padding: 2px 8px 2px 8px;
  border-radius: 100px;
}
.product-id-tag-value {
  line-height: 1.15;
}

.requested-product-id-tag {
  background-color: white;
  color: var(--product-info-light-color);
  padding: 2px 8px 2px 8px;
}

.requested-product-id-tag-value {
  line-height: 1;
  padding-top: 2px;
}
