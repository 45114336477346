.product-name-tag {
  background-color: white;
  color: var(--product-info-main-color);

  padding: 2px 8px 2px 8px;
  border-radius: 100px;
}
.product-name-tag-value {
  line-height: 1.15;
}

.requested-product-name-tag {
  background-color: white;
  color: var(--product-info-light-color);
  padding: 0px 8px 0px 8px;
}

.requested-product-name-tag-value {
  line-height: 1;
  padding-top: 2px;
}
