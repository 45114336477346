.header {
  background-color: var(--header-background-color);
  box-shadow: 0px 2px 5px 0px rgba(59, 69, 88, 0.15);

  .logo {
    width: auto;
    height: 100%;
  }

  .desktop-header-links {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .user-info {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .mobile-content {
    display: none;

    @media (max-width: 1000px) {
      display: flex;
    }
  }
}
