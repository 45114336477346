.auth-bg {
  background: linear-gradient(300deg, rgba(243, 149, 0, 1) 40%, rgba(249, 188, 71, 1) 100%);

  .auth-container {
    @media (max-width: 600px) {
      width: 100%;
      height: max-content;
    }

    @media (min-width: 1200px) {
      width: 700px;
    }
  }
}

.basf-logo {
  height: 72px;
}

.pacific-logo {
  height: 36px;
}

.two-logos {
  gap: 26px;
}
