.product-companies-table :global(.p-datatable-thead) {
  tr th:last-child {
    border-left: none;
  }

  tr th:nth-last-child(2) {
    border-left: none;
    border-right: none;
  }
}

.product-companies-table :global(.p-datatable-tbody) {
  tr td:last-child {
    border-left: none;
  }

  tr td:nth-last-child(2) {
    border-left: none;
    border-right: none;
    color: #6fb12c;
  }
}
