.dropdown {
  :global(.p-dropdown) {
    background-color: #b0bbc54d;
    color: #27292d;
    border: none;
    font-weight: 500;
    min-width: 4rem;
    font-size: 1rem;
    height: 36px !important;
  }

  :global(.p-dropdown:hover) {
    background-color: #f3950033 !important;
    color: #27292d;
    border: none;
    font-weight: 500;
    min-width: 4rem;
    font-size: 1rem;
  }

  :global(.p-dropdown:not(.p-disabled).p-focus) {
    box-shadow: none;
    border: none;
  }

  :global(.p-dropdown-label) {
    padding: 10px 10px;
  }
}
